// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-chronik-js": () => import("./../../../src/pages/Chronik.js" /* webpackChunkName: "component---src-pages-chronik-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/Datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/Impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kalender-js": () => import("./../../../src/pages/Kalender.js" /* webpackChunkName: "component---src-pages-kalender-js" */),
  "component---src-pages-preisliste-js": () => import("./../../../src/pages/Preisliste.js" /* webpackChunkName: "component---src-pages-preisliste-js" */),
  "component---src-pages-satzung-js": () => import("./../../../src/pages/Satzung.js" /* webpackChunkName: "component---src-pages-satzung-js" */),
  "component---src-pages-standaufsichten-js": () => import("./../../../src/pages/Standaufsichten.js" /* webpackChunkName: "component---src-pages-standaufsichten-js" */)
}

